import React, { useState } from 'react'
import { HireInterface } from '@src/interfaces/requisitions'
import { IdStatuses } from '@src/interfaces/employees'
import { Button, StatusPopup, TextButton, Token, useStatusPopup } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { FormattedMessage } from 'react-intl'
import { cancelRequisitionHire } from '@src/api/requisitions'

type CancelHireActionProps = {
  hire: HireInterface
  onRefresh: () => void
}

export const CancelHireAction = ({ hire, onRefresh }: CancelHireActionProps) => {
  const [pending, setPending] = useState(false)
  const statusPopup = useStatusPopup()
  const showCancelHire = [IdStatuses.hired, IdStatuses.pending].includes(hire.status)
  if (!showCancelHire) {
    return null
  }
  const showSuccessPopup = () => {
    statusPopup.show(
      <StatusPopup variant="success" onClose={statusPopup.hide}>
        <StatusPopup.Title>
          <FormattedMessage id="common.success" defaultMessage="Success" />
        </StatusPopup.Title>
        <StatusPopup.Description>
          <FormattedMessage
            id="recruitment.hires.cancelHire.successDescription"
            defaultMessage="Candidate removed from this requisition"
          />
        </StatusPopup.Description>
      </StatusPopup>,
    )
  }
  const showErrorPopup = (description?: string) => {
    statusPopup.show(
      <StatusPopup variant="error" onClose={statusPopup.hide}>
        <StatusPopup.Title>
          <FormattedMessage
            id="recruitment.hires.cancelHire.errorTitle"
            defaultMessage="There was an error"
          />
        </StatusPopup.Title>
        <StatusPopup.Description>
          {description || <FormattedMessage id="label.failed-state" />}
        </StatusPopup.Description>
      </StatusPopup>,
    )
  }
  const handleCancelHireAction = async () => {
    setPending(true)
    try {
      await cancelRequisitionHire(hire.id, hire.requisition.id)
      showSuccessPopup()
    } catch (e) {
      showErrorPopup(getStringMessageFromError(e))
    } finally {
      setPending(false)
      onRefresh()
    }
  }
  const showConfirmationPopup = () => {
    statusPopup.show(
      <StatusPopup variant="warning" onClose={statusPopup.hide}>
        <StatusPopup.Title>
          <FormattedMessage id="common.areYouSure" defaultMessage="Are you sure?" />
        </StatusPopup.Title>
        <StatusPopup.Description>
          <FormattedMessage
            id="recruitment.hires.cancelHire.confirmation"
            defaultMessage="This action will remove this candidate from the requisition and free up the headcount. Please make sure that the offer was cancelled and that the candidate won’t be joining the company under the current requisition"
          />
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={statusPopup.hide}>
            Back
          </Button>
          <Button onClick={handleCancelHireAction} aria-label="Confirm cancel hire">
            Cancel hire
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <TextButton
        color={Token.color.blue}
        disabled={pending}
        onClick={e => {
          e.stopPropagation()
          showConfirmationPopup()
        }}
      >
        Cancel hire
      </TextButton>
    </>
  )
}
